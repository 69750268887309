import React from "react";

const Certificates = () => {
  return (
    <>
      <style jsx>{`
        .gradient-aqua {
          left: 0;
          top: 60px;
          width: 250px;
          height: 250px;
          background: radial-gradient(
            circle at 50% 50%,
            #00d0ff,
            rgba(76, 0, 255, 0)
          );
          opacity: 0.9;
        }

        .gradient-aqua,
        .hero-gradient-wrapper {
          position: absolute;
          border-radius: 50% 22% 40% 80%;
          filter: blur(100px);
          z-index: 0;
        }

        .glass-box {
          background: #224452;
          border-radius: 16px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          -webkit-backdrop-filter: blur(5px);
          border: 1px solid #969696;
          padding: 50px;
        }

        .cust-hero-card {
          border: none;
          height: 100% !important;
          padding: 5px;
          border-radius: 10px;
        }

        .cert-cont p {
          padding-right: 70px;
        }

        .dark-grad {
          background-image: linear-gradient(to right, #396577, #234451);
        }

        .cert-img {
          width: 50px;
        }

        .cust-text-format p {
          font-size: 14px;
          font-weight: 500;
          color: #000 !important;
        }
      `}</style>
      {/* ------------- certificate end --------- */}
      <div class="cust-padding-2">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-12">
              <div class="end-cta-sec text-center">
                <h2
                  class="title wow animate__bounceInn text-white mb-4 animated"
                  data-animation="fadeInUp"
                  data-delay="1.5s"
                >
                  <span class="grad-text">Complimentary,</span> No-Obligation
                  Trial
                </h2>

                <p>
                  We offer qualified prospects an opportunity to experience our
                  solutions firsthand, absolutely free of charge and without any
                  obligations. This trial is our way of demonstrating the
                  tangible benefits our services can bring to your business.
                  Don't hesitate—schedule a meeting with us today, and let's
                  embark on a journey toward your company's growth together.
                </p>

                <div class="mt-5">
                  <a
                    href=""
                    class="main-btn mx-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                  >
                    Book your meeting now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificates;
