import React from "react";

//best practice imports
import practiceIcon1 from "../../../../src/app/assets/images/plug-and-play/icons/listed/1.png";
import practiceIcon2 from "../../../../src/app/assets/images/plug-and-play/icons/listed/2.png";
import practiceIcon3 from "../../../../src/app/assets/images/plug-and-play/icons/listed/3.png";
import practiceIcon4 from "../../../../src/app/assets/images/plug-and-play/icons/listed/4.png";
import practiceIcon5 from "../../../../src/app/assets/images/plug-and-play/icons/listed/5.png";
import practiceIcon6 from "../../../../src/app/assets/images/plug-and-play/icons/listed/6.png";

const Practices = () => {
  return (
    <>
      <style jsx>{`
        .listed-cont {
          background-color: #214453;
          border: 1px solid #484848;
          border-radius: 10px;
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          padding: 25px;
          height: 100%;
        }
        .listed-img {
          flex-shrink: 0;
          padding: 10px;
          background-color: rgb(37 37 37 / 60%);
          border: 2px #828282 inset;
          border-radius: 10px;
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
        }
        .listed-img img {
          width: 40px;
        }
        .listed-cont h3 {
          font-size: 28px;
          line-height: 1.4;
          color: #fff !important;
          margin-bottom: 0px;
        }
        .listed-cont p {
          font-size: 18px;
          line-height: 1.4;
          color: #fff !important;
          margin-bottom: 0;
        }
      `}</style>
      <div class="cust-padding-2">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12">
              <h2
                class="title wow animate__bounceInn text-white animated mb-5 text-center animated"
                data-animation="fadeInUp"
                data-delay="1.5s"
              >
                Our Top <span class="grad-text">Best Practices</span>
              </h2>
            </div>

            <div class="col-md-12">
              <div class="row g-3">
                <div class="col-lg-6">
                  <div class="listed-cont">
                    <div class="d-flex align-items-center mb-3">
                      <div class="listed-img me-3">
                        <img src={practiceIcon1} alt="" class="img-fluid" />
                      </div>
                      <div>
                        <h3 class="">Employee Training and Development</h3>
                      </div>
                    </div>
                    <p>
                      Continuous training program ensure agents are updated with
                      product knowledge, communication skills, and service
                      techniques.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="listed-cont">
                    <div class="d-flex align-items-center mb-3">
                      <div class="listed-img me-3">
                        <img src={practiceIcon2} alt="" />
                      </div>
                      <div>
                        <h3 class="">Quality Assurance</h3>
                      </div>
                    </div>
                    <p>
                      Regular monitoring maintains high service standards and
                      identifies areas for improvement.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="listed-cont">
                    <div class="d-flex align-items-center mb-3">
                      <div class="listed-img me-3">
                        <img src={practiceIcon3} alt="" />
                      </div>
                      <div>
                        <h3 class="">Efficient Use of Technology</h3>
                      </div>
                    </div>
                    <p>
                      Leveraging advanced call center technologies such as CRM
                      systems, ACD, IVR, and workforce management tools for
                      enhanced productivity.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="listed-cont">
                    <div class="d-flex align-items-center mb-3">
                      <div class="listed-img me-3">
                        <img src={practiceIcon4} alt="" />
                      </div>
                      <div>
                        <h3 class="">Performance Metrics and KPIs</h3>
                      </div>
                    </div>
                    <p>
                      Monitoring KPIs like AHT, FCR, CSAT scores, and NPS to
                      gauge effectiveness and identify improvement
                      opportunities.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="listed-cont">
                    <div class="d-flex align-items-center mb-3">
                      <div class="listed-img me-3">
                        <img src={practiceIcon5} alt="" />
                      </div>
                      <div>
                        <h3 class="">Proactive Communication</h3>
                      </div>
                    </div>
                    <p>
                      Keeping customers informed about new products, services,
                      or changes and reaching out proactively to resolve
                      potential issues.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="listed-cont">
                    <div class="d-flex align-items-center mb-3">
                      <div class="listed-img me-3">
                        <img src={practiceIcon6} alt="" />
                      </div>
                      <div>
                        <h3 class="">Agent Empowerment</h3>
                      </div>
                    </div>
                    <p>
                      Empowering agents with authority and resources to resolve
                      issues effectively, improving both Employee satisfaction
                      and customer experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----best practice end ---- */}
    </>
  );
};

export default Practices;
