import React from "react";
//Certificate imports
import galCollage from "../../../../src/app/assets/images/plug-and-play/gal-collage.png";
import cert1 from "../../../../src/app/assets/images/plug-and-play/certi1.png";
import cert2 from "../../../../src/app/assets/images/plug-and-play/certi2.png";
import cert3 from "../../../../src/app/assets/images/plug-and-play/certi3.png";

const Tech = () => {
  return (
    <>
      <style jsx>{`
        .transform-img img {
          width: 636px;
        }

        .strategy-sec p {
          padding: 0 130px;
          margin-bottom: 30px;
        }

        .card-grad-1 {
          background: #214453;
          border-radius: 10px;
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          border: 1px solid rgb(58 192 194);
          margin-right: 25px;
        }

        .card-grad-1,
        .card-grad-2 {
          height: auto !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          padding: 30px;
        }

        .card-grad-2 {
          background-color: #214453;
          border: 1px solid #969696;
          border-radius: 10px;
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
        }
      `}</style>
      {/*---------- tech end --------- */}
      <div class="cust-padding">
        <div class="container position-relative custom-cn-width">
          <div class="gradient-aqua"></div>
          <div class="glass-box position-relative">
            <div class="row align-items-center">
              <div class="col-xxl-6">
                <div class="cert-cont">
                  <h2
                    class="title wow animate__bounceInn text-white mb-4 animated"
                    data-animation="fadeInUp"
                    data-delay="1.5s"
                  >
                    Certified Excellence
                  </h2>
                  <p class="text-white">
                    Our programs are bolstered by CAP Digisoft's ISO 27001 and
                    HIPAA certifications, emphasizing our commitment to quality
                    and data security in bringing your app to life. Through a
                    combination of expert guidance, strategic development, and
                    comprehensive market analysis, we aim to elevate your app
                    into the successful 0.5%, ensuring it is not only developed
                    with precision but also positioned for market success and
                    growth.
                  </p>
                </div>
              </div>

              <div class="col-xxl-6">
                {/* <div class="text-center mt-xxl-0 mt-5">
                  <img src={galCollage} class="img-fluid" alt="" />
                </div> */}
                

                <div class="mt-5">
                  <div class="row g-3">
                    <div class="col-lg-4">
                      <div class="card cust-hero-card dark-grad">
                        <div class="card-body">
                          <div class="mb-4">
                            <img src={cert3} class="cert-img" alt="" />
                          </div>
                          <p class="mb-0 text-white">
                            HIPAA
                            <br />
                            Compliance
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="card cust-hero-card dark-grad">
                        <div class="card-body ">
                          <div class="d-flex flex-wrap mb-4 align-items-center gap-2">
                            <div>
                              <img src={cert1} class="cert-img" alt="" />
                            </div>
                            <p class="mb-0 ps-1 certfi-num">9001:2015</p>
                          </div>

                          <p class="mb-0 text-white">
                            Business Process
                            <br />
                            (Process Facilities)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="card cust-hero-card dark-grad">
                        <div class="card-body ">
                          <div class="d-flex flex-wrap mb-4 align-items-center gap-2">
                            <div>
                              <img src={cert2} class="cert-img" alt="" />
                            </div>
                            <p class="mb-0 ps-1 certfi-num">27001:2013</p>
                          </div>

                          <p class="mb-0 text-white">
                            Data Center
                            <br />
                            (IBM and AWS)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tech;
