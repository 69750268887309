import React, { useState, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

//Testimonial imports
import callToAction from "../../../../src/app/assets/images/plug-and-play/transform.png";
import testimonialVdo from "../../../../src/app/assets/images/plug-and-play/testimonial/Testimonial-Video-v2.mp4";
import testimonialPoster from "../../../../src/app/assets/images/plug-and-play/testimonial/Testmonial-Thumbnail.jpg";
import clientOne from "../../../../src/app/assets/images/plug-and-play/testimonial/c1.png";
import clientTwo from "../../../../src/app/assets/images/plug-and-play/testimonial/c2.png";
import clientThree from "../../../../src/app/assets/images/plug-and-play/testimonial/c3.png";

const testimonials = [
  {
    name: "Anil Keswani",
    image: clientOne,
    text: `CAP Digisoft Team has worked on several websites
    for me. I have not found a better company to work
    with. They are fast and thorough. CEO has built a
    strong team.`,
  },
  {
    name: "Cody Ngo",
    image: clientTwo,
    text: `"Thank you and your team for such an outstanding job
    with the website and met all our requirements. We
    appreciate the professionalism and quick turnaround
    time!. I enjoyed working with you and your team and
    will definitely keep you guys in mind for future
    project.`,
  },
  {
    name: "Terry Johnson",
    image: clientThree,
    text: `I want to thank you for all that you do, week in
    and week out, helping us fight the battle to bring
    in revenue and new clients. You work with diligence,
    excellence and integrity, and I’m grateful for you.
    You help us make a difference in the lives of
    business owners and their families across North
    America, in the lives of our team, and in my life as
    well. I am most appreciative, and thankful.`,
  },
];

const Testimonials = () => {
  const ReadMore = ({ children }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text-light">
        {isReadMore ? `${children.slice(0, 80)}...` : children}
        <span
          onClick={toggleReadMore}
          className="read-or-hide"
          style={{ color: "#bcbcbc", cursor: "pointer", fontWeight: "500" }}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </span>
      </p>
    );
  };
  const properties = {
    prevArrow: (
      <button className="cs-btn ib-btn-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn btn-right ib-btn-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </button>
    ),
  };
  return (
    <>
      <style jsx>{`
        #info,
        .card-grad-1 h4,
        .card-grad-1 p,
        .card-grad-2 h4,
        .card-grad-2 p,
        .card-grad-3 h4,
        .card-grad-3 p,
        .cust-testi-info h4,
        .cust-testi-info p,
        .main-side-content h1,
        .main-side-content p,
        .main_menu ul li a,
        .process_content h4,
        .process_content p,
        .testivdo-cont h4,
        .testivdo-cont p,
        .testtimonial_content p {
          color: #fff;
        }
        .testi-vdo {
          width: 100%;
          border-radius: 10px;
        }
        .testi-card {
          height: 100% !important;
          background: 0 0;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 16px;
        }

        .testimonial_wrapper {
          margin: 0;
          box-shadow: none;
        }
        .testtimonial_content {
          background: #214453;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          border-radius: 16px 16px 0 0;
          padding: 20px;
          min-height: 170px;
        }
        .mb-10,
        .removemargin,
        .testimonial_star {
          margin-bottom: 10px;
        }
        .testimonial_star i {
          color: #fa0;
          font-size: 14px;
        }
        .testtimonial_content p {
          margin-bottom: 0px;
        }
        .cust-testi-info {
          background: #2b5465;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          border-radius: 0 0 16px 16px;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
        }
        .client_info {
          padding: 20px;
        }
        .client_img img {
          border-radius: 100px;
          width: 40px;
        }
        .client_meta {
          margin-left: 10px;
          text-align: left;
          margin-top: 10px;
        }
        .client_meta h4 {
          color: #fff;
          margin-bottom: 5px;
        }
        .testimonial_wrapper h4 {
          font-size: 18px;
        }
        .cusr-br,
        .cust-br,
        .header_area.sticky-bar .dark-logo,
        .light-logo,
        .slick-initialized .slick-slide {
          display: block;
        }
        .slick-slide {
          display: none;
          float: left;
          height: 100%;
          min-height: 1px;
        }
        .slick-slide {
          margin: 0 10px;
        }
        ul.slick-dots li button {
          height: 10px;
          width: 10px;
          background: #fff;
          border-radius: 50px;
          font-size: 0;
          opacity: 0.3;
        }
        .slick-track:after,
        .slick-track:before {
          display: table;
          content: "";
        }
        .slick-track:after {
          clear: both;
        }
        ul.slick-dots {
          width: 70%;
          text-align: center;
          padding-top: 20px;
          bottom: -36px;
        }
        ul.slick-dots li.slick-active button {
          background-color: #fff;
          opacity: 1;
        }
        ul.slick-dots li {
          display: inline-block;
          text-align: center;
          margin: 0px 7px 0px 0px;
          width: auto;
        }
        .testimonial-active {
          padding: 0 200px;
        }
      `}</style>
      <div class="clients_area cust-padding bg-black">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-md-12">
              <div class="section_wrapper text-center mb-lg-5 mb-4">
                <h2
                  class="title wow animate__fadeIn text-white"
                  data-animation="fadeInUp"
                  data-delay="2s"
                  data-wow-duration="2.5s"
                >
                  Our Client <span class="grad-text">Testimonials</span>
                </h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mb-5">
            <div class="col-xl-9">
              <div class="testivdo-cont text-center mb-4">
                <h4 class="mb-3">Raving Reviews: Our Client's Success Story</h4>
                <p>
                  Unveil the inspiring transformation of one of our valued
                  clients in their own words. Dive into their captivating
                  testimonial to learn how our services played a pivotal role in
                  their journey to success. Get ready to be motivated and see
                  how we can make a difference for you too. Don't miss this
                  extraordinary client success story.
                </p>
              </div>

              <video
                class="testi-vdo"
                poster={testimonialPoster}
                controls="autoplay"
              >
                <source src={testimonialVdo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-9">
              <Slide
                className="brand-slider plug-slider px-lg-5"
                easing="ease"
                {...properties}
                slidesToScroll={1}
                slidesToShow={2}
                autoplay={true}
                arrows={false}
                indicators={true}
                responsive={responsiveSettings}
              >
                {testimonials.map((testimonial, index) => (
                  <div className="each-slide mx-3" key={index}>
                    <div className="card testi-card">
                      <div className="testimonial_wrapper wow animate__fadeIn">
                        <div class="testtimonial_content">
                          <div class="testimonial_star">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                          </div>

                          <ReadMore>{testimonial.text}</ReadMore>
                        </div>

                        <div class="rows">
                          <div class="client_info client_info-one client_info_three d-flex cust-testi-info">
                            <div class="client_img">
                              <img src={testimonial.image} alt="author_info" />
                            </div>
                            <div class="client_meta">
                              <h4>{testimonial.name}</h4>
                              {/*
                          <!-- <p>ABC CEO</p> --> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
        </div>
      </div>

      {/* -----testimonial end ------- */}

      
      <div class="cust-padding">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <h2
                class="title wow animate__bounceInn text-white animated mb-4 animated"
                data-animation="fadeInUp"
                data-delay="1.5s"
              >
                We warmly{" "}
                <span class="grad-text">
                  invite <br class="d-md-block d-none" />
                  you
                </span>{" "}
                to visit us!
              </h2>

              <p class="text-white">
                Located in El Salvador, our call center operates in close
                synergy with our head office in Dallas. We're eager to host you
                in El Salvador, offering you a firsthand glimpse into our
                vibrant work culture and providing an opportunity to engage
                directly with our exceptional team of agents. It's an excellent
                chance for you to experience the dedication and expertise that
                define our services and witness how we can transform your
                customer support operations firsthand.
              </p>

              <div class="mt-5">
                <a
                  class="main-btn"
                  href=""
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                >
                  {" "}
                  Schedule a consultation today!
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="text-center transform-img">
                <img src={callToAction} alt="" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const responsiveSettings = [
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 320,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export default Testimonials;
