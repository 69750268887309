import React from "react";

//AI gif imports
import aiGif from "../../../../src/app/assets/images/plug-and-play/ai.gif";
import tech1 from "../../../../src/app/assets/images/plug-and-play/icons/1.png";
import tech2 from "../../../../src/app/assets/images/plug-and-play/icons/8.png";

const CTA = () => {
  return (
    <>
      {/* ----------CTA1 end-------- */}
      <div class="cust-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="text-center strategy-sec">
                <h2
                  class="title wow animate__bounceInn text-white animated mb-4 animated"
                  data-animation="fadeInUp"
                  data-delay="1.5s"
                >
                  We embrace technology <br class="cust-br" />
                  <span class="grad-text">swiftly and efficiently</span>
                </h2>

                <p class="text-white">
                  Our call center services and our agents are at the forefront
                  of technological advancements. Leveraging AI, we automate
                  follow-ups, ensuring no opportunity is missed. With Machine
                  Learning, we gain insights into prospect behavior, optimizing
                  our follow-up schedule for maximum engagement. Our use of Auto
                  Dialers enables us to contact prospects simultaneously,
                  enhancing our outreach efficiency. Recognizing that adaptation
                  and innovation are key to success, we are dedicated to
                  integrating these proven technologies into our Plug and Play
                  Call Center solutions, ensuring our clients benefit from the
                  most cutting-edge tools and methods available.
                </p>

                <h4 class="text-white">Our Commitment to Excellence</h4>
              </div>
            </div>

            <div class="col-xl-8">
              <div class="d-flex ai-card mt-4">
                <div class="card-grad-1">
                  <h4 class="mb-2">AI-Driven Efficiency</h4>
                  <p>
                    Our agents are equipped with AI tools for superior service.
                  </p>

                  <div class="text-center mt-5">
                    <img src={aiGif} alt="" />
                  </div>
                </div>

                <div>
                  <div class="card-grad-2 mb-4">
                    <div class="d-flex">
                      <div class="flex-shrink-0 pe-4">
                        <img src={tech1} alt="" style={{ width: "40px" }} />
                      </div>
                      <div>
                        <h4 class="mb-2">Personalized Services</h4>
                        <p class="mb-0">
                          Tailored solutions that align with your business
                          goals.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card-grad-2">
                    <div class="d-flex">
                      <div class="flex-shrink-0 pe-4">
                        <img src={tech2} alt="" style={{ width: "40px" }} />
                      </div>
                      <div>
                        <h4>Operational Excellence</h4>
                        <p class="mb-0">
                          We set and monitor high productivity standards to
                          ensure unparalleled efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTA;
