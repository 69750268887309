import React, { useState, useEffect } from "react";
import "../PlugAndPlay/custom.css";
import "react-slideshow-image/dist/styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// hero banner imports
import heroCenter from "../../../../src/app/assets/images/plug-and-play/hero-center.webp";
import heroCenter1 from "../../../../src/app/assets/images/plug-and-play/hero-center1.webp";
import heroCenter2 from "../../../../src/app/assets/images/plug-and-play/hero-center-2.webp";

const Banner = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Preload the banner image */}
      <link rel="preload" href={heroCenter} as="image" />
      <link rel="preload" href={heroCenter1} as="image" />
      <link rel="preload" href={heroCenter2} as="image" />

      <style jsx>{`
        .slide_area {
          overflow: hidden;
          background: #13303c;
          background-repeat: no-repeat;
          background-position: left center;
          margin-top: -117px;
          padding-top: 117px;
        }

        .slide_content p {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.5;
          padding-top: 10px;
        }

        .typing-effect p {
          font-size: 62px;
          font-weight: 700;
          overflow: hidden;
          color: #fff;
          line-height: 1.4;
          margin-bottom: 12px;
        }

        .hero-para {
          font-size: 22px !important;
          padding: 0px 100px;
        }

        .typing-effect p span.typed-text {
          font-weight: 700;
          color: #d600d6;
          background-clip: text !important;
          -webkit-background-clip: text !important;
          height: 84px;
          display: block;
        }

        .grad-text,
        .typing-effect p span.typed-text {
          color: #52bdc7;
        }

        .grad-text-dark {
          color: #0c5b79 !important;
        }

        .plug-container {
          position: relative;
          max-width: 1920px;
          width: 100%;
          padding-right: var(--bs-gutter-x, 0.75rem);
          padding-left: var(--bs-gutter-x, 0.75rem);
          margin-right: auto;
          margin-left: auto;
        }

        .padding-plug {
          padding: 30px 0 100px;
        }

        .plug-play_wrapper {
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          width: 100%;
        }

        .plug-left,
        .plug-right {
          position: relative;
          width: 37.5%;
          flex-shrink: 0;
          top: -30px;
        }

        .plug-left-child,
        .plug-right-child {
          position: absolute;
          width: 100%;
        }

        .slider,
        img.imgPerson,
        img.imgPerson2 {
          position: relative;
          margin: auto;
        }

        img.imgPerson,
        img.imgPerson2 {
          height: 120px;
          object-fit: contain;
        }

        .hero-center {
          width: 100%;
        }

        .plug-play-page .slider {
          box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
          height: auto;
          margin: auto;
          overflow: hidden;
          position: relative;
          width: 1920px;
          margin-top: 60px;
        }

        .plug-play-page .slider::before {
          left: 0;
          top: 0;
        }

        .slider::after {
          right: 0;
          top: 0;
          transform: rotateZ(180deg);
        }

        .slider::before,
        .slider::after {
          background: linear-gradient(
            to right,
            #0d2e3d 0%,
            rgba(255, 255, 255, 0) 100%
          );
          content: "";
          position: absolute;
          width: 200px;
          z-index: 2;
          height: 60px;
        }

        .slider .slide-track {
          -webkit-animation: scroll 60s linear infinite;
          animation: scroll 60s linear infinite;
          display: flex;
          width: calc(300px * 14);
        }

        .slider .slide {
          height: auto;
          width: 300px;
          line-height: 60px;
        }

        .carousel-testimonial-img,
        .hero-center,
        .idp-head,
        .main-btn,
        .serv-icons,
        .slider .slide,
        .timeline,
        .typing-effect p {
          text-align: center;
        }
      `}</style>

      <div class="slide_area">
        <div class="container-fluid position-relative ">
          <div class="hero-gradient-wrapper "></div>

          <div class="container custom-cn-width">
            <div class="row align-items-center position-relative hero-padding">
              <div class="col-12">
                <div class="slide_content p-0 text-center">
                  <div class="typing-effect">
                    <p>
                      Plug and Play{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        fill="currentColor"
                        class="bi bi-plugin"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 1 2.898 5.673c-.167-.121-.216-.406-.002-.62l1.8-1.8a3.5 3.5 0 0 0 4.572-.328l1.414-1.415a.5.5 0 0 0 0-.707l-.707-.707 1.559-1.563a.5.5 0 1 0-.708-.706l-1.559 1.562-1.414-1.414 1.56-1.562a.5.5 0 1 0-.707-.706l-1.56 1.56-.707-.706a.5.5 0 0 0-.707 0L5.318 5.975a3.5 3.5 0 0 0-.328 4.571l-1.8 1.8c-.58.58-.62 1.6.121 2.137A8 8 0 1 0 0 8a.5.5 0 0 0 1 0"
                        />
                      </svg>{" "}
                      Call Center Service for <br class="d-xl-block d-none" />
                      <span class="typed-text">Expert Tech Support</span>
                      <span class="cursor"></span>
                    </p>
                  </div>

                  <p class="text-white hero-para">
                    Comprehensive Plug and Play Call Center Service, empowering
                    clients. Efficiently manage support and outreach, trust
                    professionalism and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="position-relative">
          <div class="padding-plug">
            <div className="plug-play-main-wrapper">
              {!isScrolled ? (
                <div class="plug-play_wrapper mx-auto position-relative text-center">
                  <div class="">
                    <LazyLoadImage
                      src={heroCenter}
                      alt="ib-img"
                      effect="blur"
                      width="100%"
                      height="auto"
                    />
                  </div>
                </div>
              ) : (
                <div class="plug-margin-btm">
                  <div class="hero-img-mob text-center mx-auto ">
                    <LazyLoadImage
                      src={heroCenter1}
                      alt="ib-img"
                      effect="blur"
                      width="100%"
                      height="auto"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="container plug-play-wrapper mx-auto mb-4">
              <LazyLoadImage
                src={heroCenter2}
                alt="ib-img"
                effect="blur"
                width="100%"
                height="auto"
              />
            </div>
            <div class="mx-auto text-center px-2">
              <a
                class="main-btn mx-auto"
                href=""
                data-bs-toggle="modal"
                data-bs-target="#meeting"
              >
                Schedule a Free Consultation
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
