import React from "react";

//Benfits icons imports
import benIcon1 from "../../../../src/app/assets/images/plug-and-play/icons/1.png";
import benIcon2 from "../../../../src/app/assets/images/plug-and-play/icons/2.png";
import benIcon3 from "../../../../src/app/assets/images/plug-and-play/icons/3.png";
import benIcon4 from "../../../../src/app/assets/images/plug-and-play/icons/4.png";
import benIcon5 from "../../../../src/app/assets/images/plug-and-play/icons/5.png";
import benIcon6 from "../../../../src/app/assets/images/plug-and-play/icons/6.png";

const Partner = () => {
  return (
    <>
      <style jsx>{`
        .sticky.cust-sticky {
          top: 0px;
          position: sticky;
          width: 50%;
          height: 100%;
        }
        .st-sec-wrapp {
          width: 100%;
          display: flex;
        }
        .wu-serv {
          padding: 10px;
          margin-left: 50px;
          margin-bottom: 20px;
        }
        .cust-bt-line,
        .cust-sticky,
        .strm-cont {
          position: relative;
        }
        .wu-serv p {
          color: #fff;
          margin-bottom: 30px;
          font-size: 18px;
        }
        .cust-bt-line:after {
          left: -15px;
        }
        .copyright_area:before,
        .cust-bt-line:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-image: radial-gradient(#fff 5%, #fff 15%, #14303c 60%);
        }
        .main-side-content {
          float: left;
          width: 56%;
          padding: 50px 0 0;
        }
        .main-side-content::-webkit-scrollbar {
          width: 1px;
        }
        .partner-sec {
          background-color: #13303c !important;
        }
        .wu-serv h4 {
          color: #fff;
          margin-bottom: 0;
          font-size: 28px;
          line-height: 1.4;
        }
        .wu-serv-img {
          width: 50px;
          margin-right: 10px;
        }
        .wu-serv-img img {
          width: 50px;
        }
        @media (max-width: 767px) {
          .st-sec-wrapp {
            display: inline-block;
          }
        }
      `}</style>
      <div class="cust-padding partner-sec">
        <div class="container custom-cn-width position-relative">
          <div class="st-sec-wrapp">
            <div class="sticky cust-sticky">
              <h2
                class="title wow animate__bounceInn text-white animated mb-0 animated"
                data-animation="fadeInUp"
                data-delay="1.5s"
              >
                Why <span class="grad-text">Partner</span> <br />
                with Us?
              </h2>

              <div class="counter-sec">
                <div class="d-lg-flex gap-lg-5 why-sec-count">
                  <div class="count-margin">
                    <h3>20+</h3>
                    <h4>Years in Business</h4>
                  </div>

                  <div class="count-margin">
                    <h3>3000+</h3>
                    <h4>Customers</h4>
                  </div>
                </div>

                <div class="d-lg-flex gap-lg-5 why-sec-count">
                  <div class="count-margin">
                    <h3>400+</h3>
                    <h4>Team Members</h4>
                  </div>

                  <div class="count-margin">
                    <h3>5+</h3>
                    <h4>Locations</h4>
                  </div>
                </div>
              </div>

              <div class="count-btn">
                <a
                  class="main-btn"
                  href=""
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                >
                  Connect with us by scheduling a meeting
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <div class="main-side-content">
              <article>
                <div class="wu-serv cust-bt-line">
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="wu-serv-img">
                      <img src={benIcon1} alt="" />
                    </div>
                    <h4>Cost Efficiency</h4>
                  </div>
                  <p>
                    Achieve significant savings on staffing costs while ensuring
                    high service quality.
                  </p>
                </div>

                <div class="wu-serv cust-bt-line">
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="wu-serv-img">
                      <img src={benIcon2} alt="" />
                    </div>
                    <h4>Pricing</h4>
                  </div>
                  <p>
                    Our solutions are designed to be up to 50% more
                    cost-effective, delivering unmatched value without
                    compromising on service quality.
                  </p>
                </div>

                <div class="wu-serv cust-bt-line">
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="wu-serv-img">
                      <img src={benIcon3} alt="" />
                    </div>
                    <h4>Expert Management</h4>
                  </div>
                  <p>
                    Our dedicated team manages all staffing aspects, aligning
                    with your strategic goals.
                  </p>
                </div>

                <div class="wu-serv cust-bt-line">
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="wu-serv-img">
                      <img src={benIcon4} alt="" />
                    </div>
                    <h4>Simplified Operations</h4>
                  </div>
                  <p>
                    We take on payroll, reporting, and administrative tasks,
                    allowing you to focus on core activities.
                  </p>
                </div>

                <div class="wu-serv cust-bt-line">
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="wu-serv-img">
                      <img src={benIcon5} alt="" />
                    </div>
                    <h4>Flexibility and Scalability</h4>
                  </div>
                  <p>
                    Adjust your support team's size as needed, ensuring
                    efficiency and responsiveness.
                  </p>
                </div>

                <div class="wu-serv no-pb-wu-serv">
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="wu-serv-img">
                      <img src={benIcon6} alt="" />
                    </div>
                    <h4>Cultural Alignment</h4>
                  </div>
                  <p class="no-pb-wu-serv mb-0">
                    Our nearshore team is deeply familiar with US culture,
                    ensuring a seamless service experience for your customers.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      {/* -------- sticky section end -------- */}
    </>
  );
};

export default Partner;
