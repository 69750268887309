
import React from "react";
import "../PlugAndPlay/custom.css";
import "react-slideshow-image/dist/styles.css";

// Partners imports
import partnerOne from "../../../../src/app/assets/images/plug-and-play/1.png";
import partnerThree from "../../../../src/app/assets/images/plug-and-play/3.png";
import partnerFour from "../../../../src/app/assets/images/plug-and-play/4.png";
import partnerFive from "../../../../src/app/assets/images/plug-and-play/5.png";
import partnerSix from "../../../../src/app/assets/images/plug-and-play/6.png";
import partnerSeven from "../../../../src/app/assets/images/plug-and-play/7.png";
import partnerEight from "../../../../src/app/assets/images/plug-and-play/8.png";
import partnerNine from "../../../../src/app/assets/images/plug-and-play/9.png";

const Slides = () => {
return (
<>

    {/* slide end */}
    <div class="container-fluid p-0" style={{ overflow: "hidden" }}>
      <div class="text-center">
        <p class="text-white mb-2">Our Trusted Clients</p>
      </div>
      <div class="Marquee">
        <div class="Marquee-content">
          <div class="Marquee-tag">
            <img src={partnerOne} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerThree} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFour} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFive} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerSix} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerSeven} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerEight} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerNine} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerOne} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerThree} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFour} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFive} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerSix} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerSeven} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerEight} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerNine} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerOne} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerThree} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFour} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerFive} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            {" "}
            <img src={partnerSix} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerSeven} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerEight} class="img-fluid" alt="pp-img" />
          </div>
          <div class="Marquee-tag">
            <img src={partnerNine} class="img-fluid" alt="pp-img" />
          </div>
        </div>
      </div>
    </div>

</>
);
};



export default Slides;